<script>
import {getCancellations} from "@/api/cancellations";
import Pagination from '@/components/Pagination';
import RowBlockSkeleton from "@/views/cancellation/components/RowBlockSkeleton";
import displaySettings from "@/mixin/displaySettings";

export default {
	name: "index",
	components: {RowBlockSkeleton, Pagination},
	mixins: [displaySettings],
	data() {
		return {
			data: [],
			meta: {},
			loading: false,
		}
	},
	methods: {
		getData() {
			this.loading = true;
			getCancellations().then(response => {
				this.data = response.data.data;
				this.meta = response.data.meta
				this.loading = false;
			})
		},
		getStatusName(type) {
			switch (type) {
				case 'pending':
					return 'Очікує';
				case 'in_work':
					return 'В роботі'
				case 'canceled':
					return 'Припинений'
				case 'rejected':
					return 'Відхилено'
				default:
					return '';
			}
		},
	},
	mounted() {
		this.getData();
	}
}
</script>

<template>
	<div>
		<div v-if="isMobile">
			<el-collapse :accordion="true">
				<el-collapse-item v-for="item in data" :key="item.id">
					<template #title>
						<div class="w-100">
							<el-row align="middle">
								<el-col :span="12">
									<div><b>Тип статусу</b></div>
									<div>
										{{ item.order.typeName }} Ануляція
									</div>
								</el-col>
								<el-col :span="12">
									<div>
										<b>Номер поліса</b>
									</div>
									<el-link :href="item.order.mtsbuLink" :underline="false" target="_blank">{{
											item.order.policyNumber
										}}
									</el-link>
								</el-col>
							</el-row>
						</div>
					</template>
					<div>
						<el-row :gutter="5">
							<el-col>
								<el-row>
									<el-col>
										<b>Дата</b>
									</el-col>
									<el-col>
										<span>Запит створений:</span>
										{{ item.createdAt }}
									</el-col>
									<el-col>
										<span>Поліс створений:</span>
										{{ item.order.createdAt }}
									</el-col>
								</el-row>
							</el-col>
							<el-col>
								<el-row>
									<el-col><b>Страхувальник</b></el-col>
									<el-col>
										<el-col>
											{{ item.order.insuredPersonOwner.fullName }}
										</el-col>
										<el-col v-if="item.data !== null">
											<el-col v-if="item.data.file">
												<el-link :href="'/storage/' + item.data.file" target="_blank" :underline="false">Заява</el-link>
											</el-col>
											<el-col v-if="item.data.email">
												Email: {{ item.data.email }}
											</el-col>
											<el-col v-if="item.data.reason">
												Причина: {{ item.data.reason }}
											</el-col>
											<el-col v-if="item.data.numberPolicy">
												Номер нового полісу: {{ item.data.numberPolicy }}
											</el-col>
										</el-col>
									</el-col>
								</el-row>
							</el-col>
							<el-col>
								<el-row>
									<el-col><b>Вартість полісу, грн</b></el-col>
									<el-col>{{ item.order.price }} грн</el-col>
								</el-row>
							</el-col>
							<el-col>
								<el-row>
									<el-col><b>Сума повернення, грн</b></el-col>
									<el-col>{{
											item.order.clientBonusTransactions.reduce(
												(accumulator, currentValue) => accumulator + parseFloat(currentValue.original_amount) - parseFloat(currentValue.bonus_amount),
												0
											).toFixed(2)
										}} грн.</el-col>
								</el-row>
							</el-col>
						</el-row>
					</div>
				</el-collapse-item>
			</el-collapse>
		</div>
		<div v-else class="list">
			<div class="header-block">
				<ul class="columns-block">
					<li class="column">Тип статусу</li>
					<li class="column">Номер поліса</li>
					<li class="column">Дата</li>
					<li class="column">Страхувальник</li>
					<li class="column">Вартість полісу, грн</li>
					<li class="column">Сума повернення, грн</li>
				</ul>
			</div>
			<div v-if="loading">
				<row-block-skeleton v-for="item in 4" :key="item"/>
			</div>
			<div v-else>
				<div class="row-block" v-for="item in data">
					<ul class="columns-block">
						<li class="column">
							<el-col>
								{{ item.order.typeName }} Ануляція
							</el-col>
							<el-col class="right-border">
								<div class="d-flex align-middle">
									<div>
										<span>Статус:</span>
									</div>
									<div :class="['status', `status-${item.status}`]">{{ getStatusName(item.status) }}</div>
								</div>
							</el-col>
						</li>
						<li class="column">
							<el-col>
								<el-link :href="item.order.mtsbuLink" :underline="false" target="_blank">{{
										item.order.policyNumber
									}}
								</el-link>
							</el-col>
							<el-col class="right-border">
								<span>Точка продажу</span>
								{{ item.order.user.fullName }}
							</el-col>
						</li>
						<li class="column">
							<el-row>
								<el-col>
									<span>Запит створений:</span>
									{{ item.createdAt }}
								</el-col>
								<el-col>
									<span>Поліс створений:</span>
									{{ item.order.createdAt }}
								</el-col>
							</el-row>
							<el-row>
								<el-col class="right-border">
									<span>СК:</span>
									{{ item.order.companyName }}
								</el-col>
							</el-row>
						</li>
						<li class="column">
							<el-row>
								<el-col>{{ item.order.insuredPersonOwner.fullName }}</el-col>
								<el-col v-if="item.data !== null">
									<el-col v-if="item.data.file">
										<el-link :href="'/storage/' + item.data.file" target="_blank" :underline="false">Заява</el-link>
									</el-col>
									<el-col v-if="item.data.email">
										Email: {{ item.data.email }}
									</el-col>
									<el-col v-if="item.data.reason">
										Причина: {{ item.data.reason }}
									</el-col>
									<el-col v-if="item.data.numberPolicy">
										Номер нового полісу: {{ item.data.numberPolicy }}
									</el-col>
								</el-col>
							</el-row>
						</li>
						<li class="column">{{ item.order.price }} грн</li>
						<li class="column">{{
								item.order.clientBonusTransactions.reduce(
									(accumulator, currentValue) => accumulator + parseFloat(currentValue.original_amount) - parseFloat(currentValue.bonus_amount),
									0
								).toFixed(2)
							}} грн.
						</li>
					</ul>
				</div>
			</div>
		</div>

		<pagination v-if="meta.total > 0"
								:total="meta.total"
								:page.sync="meta.current_page"
								:limit.sync="meta.per_page" @pagination="getData()"
								class="text-center"
		/>
	</div>
</template>

<style lang="scss" scoped>
@import "./styles/list";
</style>
<style scoped>
* >>> .el-collapse-item__header {
	height: auto;
	line-height: unset;
	&:nth-child(2n+1) {
		background: #F6F7FB;
	}
}
span {
	color:#999DA6;
}
</style>
