<script>
export default {
    name: "RowBlockSkeleton"
}
</script>

<template>
    <div class="row-block">
        <ul class="columns-block">
            <li class="column" v-for="item in 6" :key="item">
                <PuSkeleton class="skeleton-input"></PuSkeleton>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/list";
</style>
